import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Router } from '@angular/router';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { NotifierService } from 'angular-notifier';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCompressService, ResizeOptions, ImageUtilityService, IImage, SourceImage } from 'ng2-image-compress';

import { DatePipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class UserService {
  private readonly notifier: NotifierService;

  constructor(private datePipe:DatePipe,private apollo: Apollo, private router: Router, private httpclient: HttpClient,
    private imgCompressService: ImageCompressService, private notifierservice: NotifierService, private service: TokenService, private imageCompress: NgxImageCompressService) {
    this.notifier = this.notifierservice;
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);

  }
  public hideNotifications() {
    this.notifier.hideAll();
  }



  getCurrentUser() {
    return this.service.getUserInfo();
  }

  public getUsers = () => {
    try {
      return this.apollo.watchQuery({
        query: gql`
        query
        {
          GetAllUsers
           {
            user_profile_id,
            username,
           emailaddress,
            user_type,
            user_status,
            createdAt
          }
        }
      `
      }).valueChanges;
    } catch (e) {
      console.log('Users-Query:' + e);
    }
  }


  public getUser = (id) => {
    try {

      return this.apollo.watchQuery({
        query: gql`
        query User($user_profile_id : ID!)
        {
          User(user_profile_id : $user_profile_id)
           {
            user_profile_id,
            name,
            username,
            emailaddress,
            family_name,
            mobile_number,
            preferred_hosptial,
            user_type,
            building_number,
            additional_info,
            area_number,
            zone_number,
            insulin_carbohydrate_ratio,
            insulin_sensitivity_factor,
            target_blood_glucose,
            bloodtype,
            anyallergy,
            anymedical,
            dob,
            defaultcaregiver,profileImage,countryCode,target_blood_glucose_min,target_blood_glucose_max
          }
        }
      ` ,
        variables: {
          user_profile_id: id

        }
      }).valueChanges;
    } catch (e) {
      console.log('Users-Query:' + e);
    }
  }


  deleteUser(id) {
    return this.apollo.mutate({
      mutation: gql`
     mutation deleteUser($user_profile_id:ID!)
     {
       deleteUser(user_profile_id:$user_profile_id)
       {
        user_profile_id
       }
     }
      `,
      variables: {
        user_profile_id: id
      }
    });
  }


  addUser(user, ip, userid, file: any) {
  
    return this.apollo.mutate({
      mutation: gql`
      mutation signUp($username:String!,$name:String!,$emailaddress: String!,$password: String!,$dob:String,$user_type:Int!,$family_name:String!,$mobile_number:String!,$preferred_hosptial:Int!,$building_number:String!,$area_number:String!,
      $zone_number:String!,$additional_info:String!,$insulin_carbohydrate_ratio:String,$insulin_sensitivity_factor:String,
      $target_blood_glucose:String,$user_status:Int!,$last_updated_by:Int!,$created_by:Int!,$defaultcaregiver:Int,$ip_address:String!,$bloodtype:String,$anyallergy:String,$anymedical:String,$file:Upload!,
      $profileImage:String,$countryCode:String,$target_blood_glucose_min:String
      $target_blood_glucose_max:String
        )
       {
      signUp(username:$username,name:$name,emailaddress: $emailaddress,password:$password,dob:$dob,user_type:$user_type,family_name:$family_name,mobile_number:$mobile_number, preferred_hosptial:$preferred_hosptial,
      building_number:$building_number, area_number:$area_number, zone_number:$zone_number, additional_info:$additional_info, insulin_carbohydrate_ratio:$insulin_carbohydrate_ratio,
      insulin_sensitivity_factor:$insulin_sensitivity_factor, target_blood_glucose:$target_blood_glucose,user_status:$user_status, last_updated_by:$last_updated_by, created_by:$created_by,defaultcaregiver:$defaultcaregiver,
      ip_address:$ip_address,bloodtype:$bloodtype,anyallergy:$anyallergy,anymedical:$anymedical,file:$file,profileImage:$profileImage,countryCode:$countryCode,lan:"en",target_blood_glucose_min:$target_blood_glucose_min,target_blood_glucose_max:$target_blood_glucose_max) {
        token,
        success,
        failure
    }
  }
` ,
      variables: {
        username: user['username'].trim(),
        name: user['name'].trim(),
        emailaddress: user['emailaddress'].trim(),
        password: user['password'].trim(),
        user_type: Number(user['user_type']),
        family_name: user['family_name'].trim(),
        dob: this.datePipe.transform(user['dob'],"yyyy-MM-dd'T'HH:mm:ss.SSSZ").replace('00:00','18:10'),
        mobile_number: ('' + user['mobile_number']).trim(),
        preferred_hosptial: Number(user['preferred_hosptial']),
        building_number: user['building_number'].trim(),
        area_number: user['area_number'].trim(),
        zone_number: user['zone_number'].trim(),
        additional_info: user['additional_info'].trim(),
        insulin_carbohydrate_ratio: user['insulin_carbohydrate_ratio'] != null ? (user['insulin_carbohydrate_ratio']).toString() : '',
        insulin_sensitivity_factor: user['insulin_sensitivity_factor'] != null ? (user['insulin_sensitivity_factor']).toString() : '',
        target_blood_glucose: user['target_blood_glucose'] != null ? (user['target_blood_glucose']).toString() : '',
        user_status: 1,
        last_updated_by: userid,
        created_by: userid,
        ip_address:"", //ip,
        bloodtype: user['blood_type'] ? user['blood_type'].trim() : user['blood_type'],
        anyallergy: user['any_allergy'] ? user['any_allergy'].trim() : user['any_allergy'],
        anymedical: user['any_medical'] ? user['any_medical'].trim() : user['any_medical'],
        defaultcaregiver: 0,
        file: file?file:new File([""], "filename"),
        profileImage: file?this.getRandomImageName(file.name):'filename',
        countryCode:user['countryCode'],
        target_blood_glucose_max:user['tbg_max'],
        target_blood_glucose_min:user['tbg_min']
      }
      ,
      context: {
        useMultipart: true
      }
    });




  }

  updateUser(user, id, userid, file: File) {
   
    return this.apollo.mutate({
      mutation: gql
      `
      mutation updateUser($user_profile_id:ID!,$username:String!,$name:String!,$emailaddress: String!,$dob:String,$user_type:Int!,$family_name:String!,$mobile_number:String!,$preferred_hosptial:Int!,$building_number:String!,$area_number:String!,
      $zone_number:String!,$additional_info:String!,$insulin_carbohydrate_ratio:String,$insulin_sensitivity_factor:String,
      $target_blood_glucose:String,$user_status:Int!,$last_updated_by: Int!,$ip_address:String!,$defaultcaregiver:Int,$bloodtype:String,$anyallergy:String,$anymedical:String,$profileImage:String,$file:Upload,$countryCode:String,$target_blood_glucose_min:String
      $target_blood_glucose_max:String) {
        updateUser(user_profile_id :$user_profile_id ,username:$username,name:$name,emailaddress: $emailaddress,dob:$dob,user_type:$user_type,family_name:$family_name,mobile_number:$mobile_number, preferred_hosptial:$preferred_hosptial,
      building_number:$building_number, area_number:$area_number, zone_number:$zone_number, additional_info:$additional_info, insulin_carbohydrate_ratio:$insulin_carbohydrate_ratio,
      insulin_sensitivity_factor:$insulin_sensitivity_factor, target_blood_glucose:$target_blood_glucose, user_status:$user_status, last_updated_by:$last_updated_by,
      ip_address:$ip_address,defaultcaregiver:$defaultcaregiver,bloodtype:$bloodtype,anyallergy:$anyallergy,anymedical:$anymedical,profileImage:$profileImage,file:$file,lan:"en",countryCode:$countryCode,,target_blood_glucose_min:$target_blood_glucose_min,target_blood_glucose_max:$target_blood_glucose_max) {
        user_profile_id, username, user_type, name, family_name, dob, mobile_number, preferred_hosptial,
        building_number, area_number, zone_number, additional_info, insulin_carbohydrate_ratio,
        insulin_sensitivity_factor, target_blood_glucose, user_status, last_updated_by, ip_address,
        emailaddress, bloodtype, anyallergy, anymedical
    }
  }
` ,
      variables: {
        user_profile_id: id,
        username: user['username'].trim(),
        name: user['name'].trim(),
        emailaddress: user['emailaddress'].trim(),
        user_type: Number(user['user_type']),
        family_name: user['family_name'].trim(),
        dob: this.datePipe.transform(user['dob'],"yyyy-MM-dd'T'HH:mm:ss.SSSZ").replace('00:00','18:10'),
        mobile_number: ('' + user['mobile_number']).trim(),
        preferred_hosptial: Number(user['preferred_hosptial']),
        building_number: user['building_number'].trim(),
        area_number: user['area_number'].trim(),
        zone_number: user['zone_number'].trim(),
        additional_info: user['additional_info'].trim(),
        insulin_carbohydrate_ratio: (user['insulin_carbohydrate_ratio']).toString(),
        insulin_sensitivity_factor: (user['insulin_sensitivity_factor']).toString(),
        target_blood_glucose: (user['target_blood_glucose']).toString(),
        user_status: 1,
        last_updated_by: userid,
        ip_address: '1.1.1.1', //this.ip,
        bloodtype: user['blood_type'] ? user['blood_type'].trim() : user['blood_type'],
        anyallergy: user['any_allergy'] ? user['any_allergy'].trim() : user['any_allergy'],
        anymedical: user['any_medical'] ? user['any_medical'].trim() : user['any_medical'],
        defaultcaregiver: Number(user['default_caregiver']),
        file: file,
        profileImage:file==null?user['profileImage']:this.getRandomImageName(file.name),
        countryCode:user['countryCode'],
        target_blood_glucose_max:user['tbg_max'],
        target_blood_glucose_min:user['tbg_min']
      },context:{
        useMultipart:file==null?false:true
      }
    });
  }

  getIpAddress() {

    return this.httpclient.get("http://api.ipify.org/?format=json");
  }


  // getcompressed(file) {
  //   let images: Array<IImage> = [];
  //   ImageCompressService.filesToCompressedImageSource(file.target.files).then(observableImages => {
  //     observableImages.subscribe((image) => {
  //       images.push(image);
  //     });
  //   });
  // }

  signIn(user) {
   
    try {
      return this.apollo.mutate({
        mutation: gql`
      mutation signIn($emailaddress: String!,$password: String!) {
      signIn(emailaddress: $emailaddress,password:$password) {
      token
    }
  }
` ,
        variables: {
          emailaddress: user['email'],
          password: user['userpassword']
        }
      })
    } catch (e) {

      console.log(e);
    }

  }



  changeUserStatus(userid, userstatus) {
    return this.apollo.mutate({
      mutation: gql`
      mutation
      changeUserStatus($id:ID!,$user_status:Int)
      {
      changeUserStatus(user_profile_id:$id,user_status:$user_status)
       {
        user_status
      }
    }
    `,
      variables: {
        id: userid,
        user_status: userstatus
      }
    });
  }



  updatePassword(id, password) {

    return this.apollo.mutate({
      mutation: gql`
mutation adminChangePassword($user_profile_id:ID!,$password:String!){
  adminChangePassword(user_profile_id:$user_profile_id,password:$password){
    user_profile_id
  }
}
`,
      variables: {
        user_profile_id: id,
        password: password
      }

    });
  }



  getUsersByType(type) {
    return this.apollo.watchQuery({
      query: gql`
    query getuserbyusertypeAdmin($user_type:Int){
      getuserbyusertypeAdmin(user_type:$user_type)
      {
       user_profile_id, name,emailaddress }
    }
    `, variables: { user_type: type }
    }).valueChanges
  }
  getPatientsForCaregiver(id, type) {
    return this.apollo.watchQuery({
      query: gql
        `query getPatientCareGiver($care_giver_id:Int!, $isuser:String!){
  getPatientCareGiver(care_giver_id:$care_giver_id, isuser:$isuser) {
    user_care_giver_id
    patient_id
    care_giver_id
    email_address
    status
    decline_reason
    created_by
    last_updated_date
    last_updated_by
    endDate
    createdAt
    updatedAt
    user_profile_id
    username
    user_type
    name
    family_name
    dob
    mobile_number
    preferred_hosptial
    building_number
    area_number
    zone_number
    additional_info
    insulin_carbohydrate_ratio
    insulin_sensitivity_factor
    target_blood_glucose
    ip_address
    bloodtype
    anyallergy
    anymedical
    emailaddress
    defaultcaregiver
    user_id
    udid
    countryCode
    profileImage
   }
}
`, variables: { care_giver_id: id, isuser: type }
    }).valueChanges;
  }
  getCaregiversAndPatients(id,issuer) {
    return this.apollo.watchQuery({
      query: gql`
    query
    AdminGetPatientAndCareGiver($patientorCareGiverID:String!,$isuser:String){
      AdminGetPatientAndCareGiver(userid:$patientorCareGiverID,isuser:$isuser) {
        user_care_giver_id
        status
        patient_id
        care_giver_id
        emailaddress
        decline_reason
        created_by
        last_updated_date
        last_updated_by
        endDate
        createdAt
        updatedAt
        username
        user_type
        name
        family_name
        dob
        mobile_number
        preferred_hosptial
        user_profile_id
        }
    }
    `, variables: { patientorCareGiverID:String(id),isuser:issuer }
    }).valueChanges;
  }

  getUserLoginLogs(){
    return this.apollo.watchQuery({
      query:gql`
      query {
        AdminUserloginlogs {
          user_login_id
          user_id
          activity_type
          activity_date
          app_uid
          created_by
          updated_by
          endDate
          createdAt
          updatedAt
          username
          user_type
          name
          emailaddress
          lan
        }
      }
      `
    }).valueChanges;
  }
  getRandomImageName(name)
  {
    name=name.replace(' ','');
    const index=name.indexOf('.');
    return name.slice(0,index)+parseInt(Math.random()*10000+'')+name.slice(index);
     
  }
  sendNotification(users)
  {
    

    let userIds='';
    users.patientname.forEach(element => {
      userIds=userIds+element+','
    });
  userIds=userIds.slice(0, -1);
  console.log(userIds)
    return this.apollo.mutate({
      mutation: gql`
      mutation  AdminSendNotificationsMultiUsers($receiverID:String,$titleEN:String,$descEN:String,$titleAR:String,$descAR:String){
        AdminSendNotificationsMultiUsers(
        receiverID: $receiverID
        titleEN: $titleEN
        descEN: $descEN
        titleAR: $titleAR
        descAR: $descAR
      )
    }
      `,variables:{
        receiverID:userIds,
        titleEN:users.eTitle,
        descEN:users.emessage,
        titleAR:users.aTitle,
        descAR: users.amessage
      }
    });
  }
  isEmailOrUsernameExists(email,username)
  {
    
    
    return this.apollo.mutate({
      mutation:gql`
mutation isEmailExist($email:String,$username:String)
{
  isEmailExist(emailaddress:$email,username:$username)
  {
    user_profile_id
    username
    emailaddress
    failure
  }
}
      `,
      variables:{
        email:email.trim(),
        username:username.trim()
      }
    });
  }
}
