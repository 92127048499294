import { Component, Inject,OnInit,HostListener } from '@angular/core';
import {  MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
    selector:'app-dialog',
    templateUrl: 'dialog.component.html'
  })
  export class DialogComponent implements OnInit{
    message: string = "Are you sure you want to logout ?"
    confirmButtonText = "Yes"
    cancelButtonText = "Cancel"
    constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<DialogComponent>,private router:Router) {
        dialogRef.backdropClick().subscribe(() => {
          // Close the dialog
          dialogRef.close();
        });
        this.router.events
 .subscribe(() => {
   dialogRef.close();
 });
        
        if(data){
      this.message = data.message || this.message;
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      }
        }
    }
    @HostListener('window:keyup.esc') onKeyUp() {
      this.dialogRef.close();
    }
    @HostListener('document:click')
    clickOutside() {
      
    }
  ngOnInit()
  {
    
    this.dialogRef.updateSize('400px', '');
  }
    onConfirmClick(): void {
      this.dialogRef.close(true);
    }
  
  }

  /*
   const deleteDialog = this.dialog.open(DialogComponent, {
            data: {
                message: 'Are you sure want to delete?',
                buttonText: {
                    ok: 'Yes',
                    cancel: 'No'
                }
            }
        });
        deleteDialog.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.service.deleteUser(id).subscribe(result => {
                    if (result.data) {
                        this.bindUserList();
                        this.service.showNotification('success', 'User Deleted Successfully.');
                    }
                });

            }
        });
  */ 