import { Injectable } from '@angular/core';

import { JwtHelperService } from "@auth0/angular-jwt";
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private jwthelper: JwtHelperService) { }

  isAuthenticated() {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token').toString();
    
      if (token=='null' || this.jwthelper.isTokenExpired(token)) {
        
        return false;
      }
      return true;
    }
    return false;
    
  }
}
