import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import {Router,ActivatedRouteSnapshot} from '@angular/router';
import {ActiveService} from '../services/active.service';
import { MatDialog } from '@angular/material/dialog';
import {DialogComponent} from '../account/dialog/dialog.component';
import { data } from 'jquery';
export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
   /* { path: '/dashboard', title: 'Dashboard', icon: 'nc-bank', class: '' },*/
    { path: '', title: 'Users', icon: 'nc-single-02', class: '' },
    { path: '', title: 'Food', icon: 'fa fa-cutlery', class: '' },
    { path: '/categories', title: 'Categories', icon: 'fa fa-list', class: '' },
    { path: '/content', title: 'Content', icon: 'nc-caps-small', class: '' },
    { path: '/calculation', title: 'calculation History', icon: 'fa fa-calculator', class: '' },
    { path: '/preferred_hospital', title: 'Preferred Hospitals', icon: 'fa fa-hospital-o', class: '' },
    { path: '/feedback', title: 'Feedback', icon: 'fa fa-commenting', class: '' },
    
    { path: '/blood_group', title: 'Blood Group', icon: 'fa fa-tint', class: '' },
    
    { path: '/notification', title: 'Notifications', icon: 'fa fa-bell', class: '' },
    /*{ path: '/caregiver', title: 'Caregiver', icon: 'nc-single-02', class: '' },*/
  
   { path: '/user_activity', title: 'User Activity', icon: 'fa fa-users', class: '' },
   { path: '/change_password', title: 'Change Password', icon: 'fa fa-key', class: '' }
];

export const userSections:RouteInfo[]=[
    { path: '/user', title: 'Manage Users', icon: 'fa fa-bars', class: '' },
    { path: '/patients_for_caregiver', title: 'Users For Caregiver', icon: 'fa fa-bars', class: '' },
    { path: '/caregivers_for_patient', title: 'Caregivers For User', icon: 'fa fa-bars', class: '' }

];

export const foodSections:RouteInfo[]=[
    { path: '/foods_approved', title: 'Foods Approved', icon: 'fa fa-bars', class: '' },
    { path: '/foods_pending', title: 'Foods Pending', icon: 'fa fa-bars', class: '' }
];

@Component({
    moduleId: module.id,
    // tslint:disable-next-line: component-selector
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit {
    // @Output() categorySelect = new EventEmitter<any>(); //put the data type of category here.

    //use this method to set the selected category
        
    public menuItems: any[];
    public usersection:any[];
    public foodsection:any[];
     logoutDialog:any;
    constructor(private router:Router,private service :ActiveService,private dialog:MatDialog)
     {
        this.service.sendData('false');
    }
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.usersection=userSections.filter(userSection=>userSection)
        this.foodsection=foodSections.filter(foodSection=>foodSection)
    }

refresh(currentUrl)
{
    // if(this.logoutDialog!=undefined||null)
    // {
    //     this.logoutDialog.close();
    //     return;
    // }
   let url=this.router.url;
          if(url.indexOf(';')!=-1)
          {
         url= this.router.url.substr(0,this.router.url.indexOf(';'))
          }
  
if(url===currentUrl)
{
    this.service.sendData('true');
    // this.categorySelect.emit(false);
    // console.log(this.categorySelect);
//this.router.navigate(['user', { refresh: 'true' }]);
   
}

}

    logout()
    {
        if(this.logoutDialog!=undefined||null)
    {
        this.logoutDialog.close();
      
    }
     this.logoutDialog = this.dialog.open(DialogComponent, { disableClose: true });
     
     this.logoutDialog.afterClosed().subscribe(res=>{
        this.logoutDialog=null;  
       if(res)
       {
        localStorage.removeItem('token');
        this.router.navigate(['']);
       }
     })
 
    }
}
