import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './account/login/login.component'
import {AuthGuard} from './auth.guard';
import {InvalidGuard} from './invalid.guard';
export const AppRoutes: Routes = [
  {
    path: '',
    component: LoginComponent, canActivate:[InvalidGuard]
  },{
    path: 'login',
    component: LoginComponent, canActivate:[InvalidGuard]
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AdminLayoutComponent, canActivate:[AuthGuard],
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  

  {
    path: '**',
    redirectTo: 'login',canActivate:[InvalidGuard]
  }
]
