import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.token;


    if (!token) {
      
      const req = request.clone({

        headers: request.headers.set('Content-Type', 'application/json; charset=utf-8'),
      });
      return next.handle(req);
    }
    if (request.url == "http://api.ipify.org/?format=json") {
      const req = request.clone({

        headers: request.headers.set('Content-Type', 'application/json; charset=utf-8'),
      });
      return next.handle(req);
    }
    if(request.body instanceof FormData)
    {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer' + ' ' + token,
      // 'Content-Type': 'application/json; charset=utf-8',
        // 'Access-Control-Allow-Origin':'*'
      });
      const req1 = request.clone({ headers });
      return next.handle(req1);
    }
    
      const headers = new HttpHeaders({
        'Authorization': 'Bearer' + ' ' + token,
       'Content-Type': 'application/json; charset=utf-8',
        // 'Access-Control-Allow-Origin':'*'
      });
    
      const req1 = request.clone({ headers });
      return next.handle(req1);

   
  }
}
