import { Injectable } from '@angular/core';
import * as decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }
  getUserInfo()
  {
    if(localStorage.getItem('token'))
    {
      let user = localStorage.getItem('token').toString();
    user=user.replace(/^"|"$/g, '');
    return decode(user);}
    else
    {
      return '';
    }
  }
}
