import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class InvalidGuard implements CanActivate {
  constructor(private service: AuthService, private router: Router) { }

  canActivate() {
    
    if (this.service.isAuthenticated()) {
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }

}
