import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { LoginComponent } from './account/login/login.component';
import { ForgotpasswordComponent } from './account/forgotpassword/forgotpassword.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './jwt.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { MatDialogModule } from '@angular/material/dialog';
import { DialogComponent } from '../app/account/dialog/dialog.component';
import { PasswordchangedialogComponent } from '../app/account/passwordchangedialog/passwordchangedialog.component'
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCompressService, ResizeOptions, ImageUtilityService } from 'ng2-image-compress';
import { DatePipe } from '@angular/common';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { TwoDigitDecimaNumberDirective } from './account/_helpers/two-two-digit-decima-number';

const customNotifierOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12
    },
    vertical: {
      position: 'top',
      distance: 12,
      gap: 10
    }
  },
  theme: 'material',
  behaviour: {
    autoHide: 4000,
    onClick: 'hide',
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4
  },
  animations: {
    enabled: true,
    show: {
      preset: 'slide',
      speed: 300,
      easing: 'ease'
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50
    },
    shift: {
      speed: 300,
      easing: 'ease'
    },
    overlap: 150
  }
};
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    ForgotpasswordComponent,
    DialogComponent,
    PasswordchangedialogComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: false
      ,
      onSameUrlNavigation: 'ignore'
    }),
    SidebarModule,
    NotifierModule.withConfig(customNotifierOptions),
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    GraphQLModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    Ng2ImgMaxModule


  ],
  // providers: [],
  // bootstrap: [AppComponent]
  providers: [DatePipe,{
    provide: APOLLO_OPTIONS,
    useFactory: (httpLink: HttpLink) => {
      return {
        cache: new InMemoryCache(),
        link: httpLink.create({
          //  uri: 'http://ec2-34-216-41-61.us-west-2.compute.amazonaws.com:3500/graphQL'
          //  uri: 'http://192.168.25.74:3500/graphql'
          uri: 'https://api.i-sukari.com/graphql'
        }),
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
          },
          query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
          },
        }
      }
    },
    deps: [HttpLink]
  },
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    JwtHelperService,
    NgxImageCompressService, ImageCompressService, ResizeOptions],
  bootstrap: [AppComponent]
})

export class AppModule {
}
