import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../services/user.service'
@Component({
  selector: 'app-passwordchangedialog',
  templateUrl: 'passwordchangedialog.component.html'
})

export class PasswordchangedialogComponent implements OnInit {

  passwordConfirmed = false;
  changeform: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private service: UserService,
    private dialogRef: MatDialogRef<PasswordchangedialogComponent>) {


    this.changeform = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmpassword: ['', Validators.required]
    });
  }
  ngOnInit()
  {
    this.dialogRef.updateSize('700px', '');
  }
  onConfirmClick(): void {
    if (this.changeform.invalid) {
      this.submitted = true;
      return;
    }
    if (this.passwordConfirmed) {
      return
    }
    this.dialogRef.close(this.changeform.value);
  }
  get formcontrols() {

    return this.changeform.controls;
  }
  checkPasswords() {
    const newpass = this.changeform.get('password').value;
    const confirmpass = this.changeform.get('confirmpassword').value;
    this.passwordConfirmed = newpass === confirmpass ? false : true;
  }

  checkPassword() {
    const newpass = this.changeform.get('password').value;
    const confirmpass = this.changeform.get('confirmpassword').value;
    // tslint:disable-next-line: triple-equals
    if (confirmpass != '') {
      this.passwordConfirmed = newpass === confirmpass ? false : true;
    }
  }
}