import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { interval,observable } from 'rxjs';
import { map } from 'rxjs/operators'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotForm: FormGroup;
  submitted = false;
  forgot = false;
  loading=false;
private readonly notifier:NotifierService;
  constructor(private service: UserService, private formbuilder: FormBuilder, private router: Router,private notifierservice: NotifierService) {
    this.notifier=this.notifierservice;
    
    this.loginForm = formbuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")]],
      userpassword: ['', Validators.required],
      remember_me:['']
    });

    this.forgotForm = formbuilder.group({
      email: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });
  }
  get logincontrols() {
    return this.loginForm.controls;
  }
  setform(){
    this.logincontrols.email.setValue('');
    this.logincontrols.userpassword.setValue('');
  }
  ngOnInit(): void { 
    if(localStorage.getItem('remember_me')!=null)
    {
     var credentials= JSON.parse(localStorage.getItem('remember_me'))
    this.logincontrols.email.setValue(credentials.email);
    this.logincontrols.userpassword.setValue(credentials.userpassword);
    this.logincontrols.remember_me.setValue(true);
    }
  }

  get forgotcontrols() {
    return this.forgotForm.controls;
  }

  

  loginSubmit() {
   
    if (this.loginForm.invalid) {
        this.submitted = true;
           return;
        }
      this.loading=!this.loading; 
    this.service.signIn(this.loginForm.value).subscribe(result => {
      
    
      if (result.data['signIn']['token'] != null) {
        localStorage.setItem('loggedIn', JSON.stringify(true));
       
        const data = result.data['signIn'];
        
        localStorage.setItem('token', JSON.stringify(data['token']));
      if(this.logincontrols.remember_me.value==true)
      {  localStorage.removeItem('remember_me');
         localStorage.setItem('remember_me', JSON.stringify(this.loginForm.value));
        }
        if(this.logincontrols.remember_me.value==false)
        {
          localStorage.removeItem('remember_me');
          console.log('asdfadsf')
      }
        this.router.navigate(['dashboard']);
       
      }
      else
      {
        this.loading=!this.loading;
        this.service.showNotification('error', ' Email Address or Password is incorrect');
      }
    }, (error) => {
      this.loading=!this.loading;
      console.log(error);
     
    });;
  }

  forgotpassword() {
    this.forgotForm.reset();
    this.loginForm.reset();
    if (this.submitted) {
      this.submitted = false;
    }
    if (this.forgot) {
      this.forgot = false;
      return;
    }
    this.forgot = true;
  }

  forgotSubmit() {
    if (this.forgotForm.invalid) {
      this.submitted = true;
      return;
    }
    this.service.showNotification('success','Link sends to your email address')
    this.submitted = false;
    this.forgot = false;
    

  }
}
