import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
forgotForm:FormGroup;
submitted=false;
  constructor(private formbuilder: FormBuilder) {
    this.forgotForm = formbuilder.group({
      username: ['', [Validators.required]]
    })
   }
   get formcontrols()
   {
     return this.forgotForm.controls;
   }

  ngOnInit(): void {
  }
  forgotpassword()
  {
    if(this.forgotForm.invalid)
    {
      this.submitted=true;
      return;
    }
    alert('legend');
  }


}
